import * as React from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import joinClasses from '/src/functions/joinClasses'

const AccordionItem = loadable(() => import('../AccordionItem'))

const Accordion = ({ data, className }) => {
    const classes = ['c-accordion', ...(className ? [className] : [])]

    const concatenatedClasses = joinClasses(classes)

    return (
        data && (
            <div className={concatenatedClasses}>
                {data.map((node, index) => (
                    <AccordionItem key={index} data={node} />
                ))}
            </div>
        )
    )
}

Accordion.propTypes = {
    /**
     * Data
     */
    data: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            content: PropTypes.string
        })
    ),
    /**
     * Optional CSS classes
     */
    className: PropTypes.string
}

Accordion.defaultProps = {
    data: [
        {
            title: 'Accordion item title',
            content: '<p>Accordion item content</p>'
        },
        {
            title: 'Accordion item title',
            content: '<p>Accordion item content</p>'
        },
        {
            title: 'Accordion item title',
            content: '<p>Accordion item content</p>'
        },
        {
            title: 'Accordion item title',
            content: '<p>Accordion item content</p>'
        },
        {
            title: 'Accordion item title',
            content: '<p>Accordion item content</p>'
        }
    ]
}

export default Accordion
